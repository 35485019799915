var page_home_loaded = 1;
global.page_home_loaded = page_home_loaded;

var flexsliderLoad = require('flexslider'),
    flexslider = require('../modules/slider');
    
page_home = {
	load: function(){	
		this.flexslider();
		this.closeVid();
		$('.flex-direction-nav').addClass('white_slide');
	},
	flexslider: function(){
	    slider = $('.flexslider');
	    slider.each(function() {
	      flexslider.visualFull(slider);
	    });
	},
	closeVid: function(){
		$('.closeIcon').on('click', function(){
			$('.homeVid').fadeOut(150);
			setTimeout(function(){
				$('.homeVid').hide();
			}, 300)
		})
	}
}

$(window).on('load', function(){
	page_home.load();
})