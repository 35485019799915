module.exports = {
	visualFull: function (item) {
		if($(item).length===0 ) return false;
		$(item).flexslider({
			animation: 'fade',
			direction: 'horizontal',
			directionNav: true,
			controlNav: false,
			animationLoop:  true,
			pauseOnAction:  false,
			pauseOnHover:   true,
			animationSpeed:  1500,
			slideshowSpeed: 7000,
			before: function(){
				$('.leftImage').animate({
					top: '+=350'
				}, 450);
				$('.rightImage').animate({
					top: '+=350'
				}, 600);
				$('.header').removeClass('red_slide blue_slide white_slide').addClass('trasparent');
			},
			after: function(){
				$('.leftImage').animate({
					top: '-=350'
				}, 450);
				$('.rightImage').animate({
					top: '-=350'
				}, 600);
				$arrow_class = $(item).find('.flex-active-slide').data('arrow');
				$('.flex-direction-nav, .header').removeClass('trasparent red_slide blue_slide white_slide').addClass($arrow_class);
			},
		});
	},
	gallery: function (item) {
		if($(item).length===0 ) return false;
		$(item).flexslider({
			animation: 'fade',
			direction: 'horizontal',
			directionNav: true,
			controlNav: false,
			animationLoop:  true,
			pauseOnAction:  false,
			pauseOnHover:   true,
			animationSpeed:  1000,
			slideshowSpeed: 5000,
		});
	},
}


